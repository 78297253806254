@keyframes opacity-pulse {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
}

.skeleton {
  animation: opacity-pulse 2s ease-out;
  animation-iteration-count: infinite;
}
