.ion-hide {
  display: none !important;
}

.ion-hide-up {
  display: none !important;
}

.ion-hide-down {
  display: none !important;
}

@media (width >= 576px) {
  .ion-hide-sm-up {
    display: none !important;
  }
}

@media (width <= 576px) {
  .ion-hide-sm-down {
    display: none !important;
  }
}

@media (width > 768px) {
  .ion-hide-md-up {
    display: none !important;
  }
}

@media (width <= 768px) {
  .ion-hide-md-down {
    display: none !important;
  }
}

@media (width > 992px) {
  .ion-hide-lg-up {
    display: none !important;
  }
}

@media (width <= 992px) {
  .ion-hide-lg-down {
    display: none !important;
  }
}

@media (width > 1200px) {
  .ion-hide-xl-up {
    display: none !important;
  }
}

@media (width <= 1200px) {
  .ion-hide-xl-down {
    display: none !important;
  }
}

.centered-max-width {
  margin-left: auto;
  margin-right: auto;
  max-width: 992px;
}

@media (width >= 992px) {
  ion-content {
    --padding-top: 60px;
    --padding-bottom: 50px;
  }

  ion-modal,
  ion-popover {
    ion-content {
      --padding-top: 0;
      --padding-bottom: 0;
    }
  }
}
