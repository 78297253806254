@use 'sass:meta';

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/* My own CSS */
@include meta.load-css('theme/colors');
@include meta.load-css('theme/typography');
@include meta.load-css('theme/feed');
@include meta.load-css('theme/detail-pages');
@include meta.load-css('theme/font-awesome-marker');
@include meta.load-css('theme/animations');
@include meta.load-css('theme/responsive');
@include meta.load-css('theme/viewport-fixes');
@include meta.load-css('theme/swiper');

body {
  font-size: 16px;
}

.my-spinner {
  margin: 32px auto;
  width: 28px;
}

.button-spinner {
  height: 14px;
  margin-right: 4px;
}

.break-anywhere {
  line-break: anywhere;
}

ion-popover {
  &.notifications {
    --height: 60%;
    --max-width: 80%;
    --width: 500px;
  }
}

.selected-text {
  user-select: all;
  color: var(--ion-color-primary);
}

.align-icon-and-text {
  display: flex;
  align-items: center;
  gap: 4px;
}

img {
  object-fit: cover;

  &:not(.img-loaded, .img-error, .no-background) {
    background: url('/assets/img/image-loading.svg') rgb(240, 240, 240);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 50%;
  }
}
