@use 'responsive-partials';

.foursquare-button {
  --color: var(--vm-foursquare) !important;
}

.detail-page {
  --overlay-height: 248px;
  --header-height: 0;

  ion-grid {
    --ion-grid-column-padding: 0;
    --ion-grid-padding: 0;

    @include responsive-partials.for-lg-up {
      --ion-grid-column-padding: initial;
      --ion-grid-padding: initial;
    }
  }

  ion-row {
    &:first-child {
      @include responsive-partials.for-lg-up {
        margin-top: 16px;

        --overlay-height: 360px;
        --ion-grid-column-padding: 0;
      }

      @include responsive-partials.for-md-up {
        margin-bottom: 16px;
      }
    }
  }

  ion-back-button {
    --color: var(--vm-white);
  }

  users-header {
    background-color: var(--ion-color-light);
    padding: 10px calc(10px + var(--ion-safe-area-left)) 10px calc(60px + var(--ion-safe-area-right));

    users-profile-pic {
      left: calc(10px + var(--ion-safe-area-left));
    }

    p {
      color: var(--vm-font-light-color);
      font-weight: 400;
    }

    strong {
      color: var(--vm-dark-gray);
    }
  }

  .black-overlay {
    position: relative;

    &.max-half-width {
      @include responsive-partials.for-md-up {
        max-width: 50%;
      }
    }

    &::before {
      position: absolute;
      content: ' ';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      z-index: 0;
      background-color: rgba(var(--vm-black-rgb), 0.5);
    }

    .background-image {
      background: var(--vm-black-rgb);
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .black-overlay-content {
      position: relative;
      min-height: var(--overlay-height);
      padding: 16px calc(16px + var(--ion-safe-area-right)) 30px calc(16px + var(--ion-safe-area-left));
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: var(--vm-font-color);

      @include responsive-partials.for-lg-up {
        padding: 16px calc(16px + var(--ion-safe-area-right)) 16px calc(16px + var(--ion-safe-area-left));
      }

      h2 {
        color: var(--vm-white);
        font-size: 24px;
        font-weight: bolder;
        margin: 0 0 10px;
      }

      p {
        margin: 8px 16px;
        padding: 0;
      }

      .main-buttons {
        margin: 10px 0;
      }

      .foursquare-link {
        color: var(--vm-foursquare);
        font-size: 20px;
        position: absolute;
        bottom: 12px;
        left: calc(16px + var(--ion-safe-area-left));
      }
    }
  }

  app-reactions {
    color: var(--vm-font-light-color);
    margin: 24px auto 16px;
    padding: 5px calc(10px + var(--ion-safe-area-left)) 5px calc(10px + var(--ion-safe-area-right));
    z-index: 1;

    .button-like {
      position: absolute;
      right: calc(16px + var(--ion-safe-area-right));
      top: -16px;
    }

    .button-share {
      --background: var(--vm-white);
      --color: var(--ion-color-secondary);

      position: absolute;
      right: calc(84px + var(--ion-safe-area-right));
      top: -16px;
    }
  }
}

.md {
  .detail-page {
    --header-height: 56px;
  }
}

.ios {
  .detail-page {
    --header-height: calc(44px + var(--ion-safe-area-top));
  }
}

@include responsive-partials.for-lg-down {
  .slide-under-header {
    position: relative;
    margin-top: calc(var(--header-height) * -1);

    .black-overlay {
      padding-top: var(--header-height);
    }
  }
}

ion-header.transparent {
  ion-toolbar {
    --background: transparent;
    --ion-color-base: transparent !important;
  }

  &.show-background {
    ion-toolbar {
      --background: var(--ion-color-primary);
      --ion-color-base: var(--ion-color-primary) !important;
    }
  }
}
