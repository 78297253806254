.feed-item {
  background-color: var(--vm-white);
  border: 1px solid var(--vm-medium-gray);
  border-radius: 5px;
  margin: 8px auto;
  max-width: 750px;
  position: relative;

  users-header {
    p {
      color: var(--vm-font-light-color);
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .item-content {
    border-top: 1px solid var(--vm-medium-gray);
    margin: 0 10px;
    padding: 20px 0 0;
  }

  .image-only {
    margin: 0 10px;
  }

  h4 {
    color: var(--vm-font-dark-color);
    font-weight: 800;
    margin: 0;
    text-transform: uppercase;
  }

  .secondary-text {
    color: var(--ion-color-medium);
    text-transform: uppercase;
  }

  .expand {
    border-radius: 5px;
    border: 1px solid var(--vm-medium-gray);
    margin: 0 -15px;
  }

  .full-image {
    border: 1px solid var(--vm-medium-gray);
    border-radius: 5px;
    display: block;
    height: 250px;
    margin: 0 -15px;
    max-width: none;
    object-fit: cover;
    overflow: hidden;
    width: calc(100% + 30px);
  }

  .following {
    background-color: var(--vm-light-gray);
    border: 1px solid var(--vm-medium-gray);
    border-radius: 5px;
    margin: 0 -5px;
    padding: 20px 0 20px 70px;
    position: relative;

    h2 {
      font-size: 18px;
      line-height: 40px;
      margin: 0;
      vertical-align: middle;
    }

    .avatar-circle-40,
    .photo-avatar-40 {
      left: 20px;
      position: absolute;
      top: 20px;
    }
  }

  app-reactions {
    background-color: var(--vm-white);
    border-radius: 5px;
    text-transform: none;

    .button-like {
      position: absolute;
      right: calc(8px + var(--ion-safe-area-right));
      top: -30px;
    }

    .button-bookmark {
      position: absolute;
      right: calc(76px + var(--ion-safe-area-right));
      top: -30px;
    }
  }

  .missing-space {
    height: 30px;
  }
}
