@mixin for-sm-down {
  @media (width <= 576px) {
    @content;
  }
}

@mixin for-sm-up {
  @media (width > 576px) {
    @content;
  }
}

@mixin for-md-down {
  @media (width <= 768px) {
    @content;
  }
}

@mixin for-md-up {
  @media (width > 768px) {
    @content;
  }
}

@mixin for-lg-down {
  @media (width <= 992px) {
    @content;
  }
}

@mixin for-lg-up {
  @media (width > 992px) {
    @content;
  }
}

@mixin for-xl-down {
  @media (width <= 1200px) {
    @content;
  }
}

@mixin for-xl-up {
  @media (width > 1200px) {
    @content;
  }
}
