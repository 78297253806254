.italicized {
  font-style: italic;
}

a {
  text-decoration: none;
}

p {
  margin: 0 0 10px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.small {
  font-size: 14px;
}

.form-control-feedback {
  color: var(--ion-color-danger);
  margin-top: 4px;
}
