:root {
  --vm-black: rgb(0, 0, 0);
  --vm-black-rgb: 0, 0, 0;
  --vm-white: rgb(255, 255, 255); // #fff
  --vm-white-rgb: 255, 255, 255;
  --vm-light-gray: rgb(248, 248, 248); // #f8f8f8
  --vm-medium-gray: rgb(235, 235, 235); // #ebebeb
  --vm-dark-gray: rgb(100, 100, 100); // #646464
  --vm-font-color: rgb(211, 211, 211); // #d3d3d3
  --vm-font-light-color: rgb(142, 144, 147); // #8e9093
  --vm-font-dark-color: rgb(56, 71, 79); // #38474f
  --vm-border-color: rgb(221, 221, 221); // #dddddd
  --vm-border-light-color: rgb(200, 199, 204); // #c8c7cc
  --vm-bookmark: rgb(24, 147, 231);
  --vm-star: #ffd700;
  --vm-foursquare: #f94878;
}
