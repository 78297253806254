// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

.ion-color-cities {
  --ion-color-base: #38aadd;
  --ion-color-base-rgb: 56, 170, 221;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #3196c2;
  --ion-color-tint: #4cb3e0;
}

.ion-color-food {
  --ion-color-base: #f69730;
  --ion-color-base-rgb: 246, 151, 48;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #d8852a;
  --ion-color-tint: #f7a145;
}

.ion-color-culture {
  --ion-color-base: #a23336;
  --ion-color-base-rgb: 162, 51, 54;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #8f2d30;
  --ion-color-tint: #ab474a;
}

.ion-color-nightlife {
  --ion-color-base: #5b396b;
  --ion-color-base-rgb: 91, 57, 107;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #50325e;
  --ion-color-tint: #6b4d7a;
}

.ion-color-nature {
  --ion-color-base: #72b026;
  --ion-color-base-rgb: 114, 176, 38;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #649b21;
  --ion-color-tint: #80b83c;
}

.ion-color-hotels {
  --ion-color-base: #d63e2a;
  --ion-color-base-rgb: 214, 62, 42;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #bc3725;
  --ion-color-tint: #da513f;
}

.ion-color-transport {
  --ion-color-base: #006788;
  --ion-color-base-rgb: 0, 103, 136;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #005b78;
  --ion-color-tint: #1a7694;
}

.ion-color-other {
  --ion-color-base: #575757;
  --ion-color-base-rgb: 87, 87, 87;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #4d4d4d;
  --ion-color-tint: #686868;
}

/** Ionic CSS Variables **/
:root {
  --ion-padding: 8px;
  --ion-color-primary: #2abf97;
  --ion-color-primary-rgb: 42, 191, 151;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #25a885;
  --ion-color-primary-tint: #3fc5a1;
}
